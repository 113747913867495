import { useWebSocket } from '@vueuse/core';
import { useAnimation } from '@/store/animation';
import { WS_MESSAGE_TYPE } from './types';

const regionalUrl: string = (() => {
  if (process.env.NODE_ENV === 'production') {
    const region = process.env.REGION;
    const socketRoom = process.env.SOCKET_ROOM;
    const testPrefix = window.location.host.includes('test') ? 'test_' : '';
    const url = `wss://stentor.${socketRoom}.garena.com/room/sub/${testPrefix}aov_shuffle_${region}`;

    return url;
  }
  return 'ws://localhost:4000';
})();

export const { data, open, close, status, ws } = useWebSocket(regionalUrl, {
  immediate: false,
  autoReconnect: {
    delay: 3000,
    retries: 2,
  },
  onMessage(_, e) {
    try {
      const {
        type,
        content: { name },
      } = JSON.parse(e.data);
      const { addJackpotWinners } = useAnimation();

      switch (type) {
        case WS_MESSAGE_TYPE.MARQUEE:
          addJackpotWinners(name);
          break;
        default:
          break;
      }
    } catch {
      // handle ws error
    }
  },
});
