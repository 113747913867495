export default () => {
  // force single page application
  function setRootFontSize() {
    // designSafeArea = 1334px * 660px (landscape NOT include browser nav 90px)
    const designSafeWidth = 1334;
    const designSafeHeight = 660;

    const vmax = Math.max(window.innerHeight, window.innerWidth);
    const vmin = Math.min(window.innerHeight, window.innerWidth);
    const vmaxToFontSize = (vmax / designSafeWidth) * 100;
    const vminToFontSize = (vmin / designSafeHeight) * 100;
    const fontSize = Math.min(vmaxToFontSize, vminToFontSize, 100).toFixed(1);
    // set root font size and html viewport
    const elem = document.documentElement;
    elem.style.fontSize = `${fontSize}px`;
    elem.style.height = `${vmin}px`;
    elem.style.width = `${vmax}px`;
  }

  let resetTimer: null | NodeJS.Timeout;
  function resetRootFontSize() {
    if (resetTimer) {
      clearTimeout(resetTimer);
    }
    resetTimer = setTimeout(() => {
      setRootFontSize();
    }, 200);
  }

  setRootFontSize();
  window.addEventListener('resize', resetRootFontSize);
};
