import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import App from '@ui/App.vue';
import i18n from '@/i18n';
import '@/preloader';
import '@ui/styles/global.scss';
import setupLayout from '@/layout';

setupLayout();

const app = createApp(App);

app.use(createPinia());
app.use(i18n);

app.mount('#app');

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onYouTubePlayerAPIReady: any;
  }
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://a580c6743e184624889b4df938c76f37@sentry.twrevenue.garenanow.com/176',
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    integrations: [new Integrations.Vue({ app, attachProps: true })],
  });
}
