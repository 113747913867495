let uid = '';
const { UI: ui, LAUNCH_DATE: launchDate } = process.env;
const currentVersion = `${ui}_${launchDate}`;
const storageVersion = () => localStorage.getItem('version_name');

function resetStorage() {
  if (!storageVersion()) return;
  // clear localStorage if not the same version
  if (storageVersion() !== currentVersion) localStorage.clear();
}
function initStorage(userUid: string) {
  resetStorage(); // reset
  uid = userUid; // set uid
}
function setStorage(key: string, val = 'true') {
  if (!uid) {
    return;
  }
  if (!storageVersion()) localStorage.setItem('version_name', currentVersion);

  let storage = localStorage.getItem('storage') || {};
  storage = typeof storage === 'string' ? JSON.parse(storage) : {};
  storage = {
    ...storage,
    [`${key}_${uid}`]: val,
  };
  localStorage.setItem('storage', JSON.stringify(storage));
}
function isValidKey(key: string | number | symbol, object: object) {
  return key in object;
}
function getStorage(key: string) {
  let storage = localStorage.getItem('storage') || {};
  if (typeof storage === 'string') {
    storage = JSON.parse(storage);
    return isValidKey(`${key}_${uid}`, storage) ? true : null;
  }
  return null;
}

export { initStorage, setStorage, getStorage };
