/*
 * image preloader
 */

const images: string[] = [
  `animation/animation-box-fail.png`,
  `animation/animation-box-in.png`,
  `animation/animation-box-success.png`,
  `animation/animation-box02.png`,
  `popup/popup-bg-01.png`,
  `popup/popup-bg-02.png`,
  `popup/popup-bg-03.png`,
  `popup/popup-deco-01.png`,
  `popup/popup-deco-02.png`,
  `popup/popup-deco-03.png`,
  `popup/popup-deco-04.png`,
  `popup/popup-deco-05.png`,
  `popup/popup-deco-06.png`,
  `popup/popup-deco-07.png`,
  `popup/popup-deco-08.png`,
  `popup/popup-deco-09.png`,
  `popup/popup-deco-box-01.png`,
  `popup/popup-deco-box-02.png`,
  `popup/popup-deco-box-03.png`,
  `popup/popup-deco-box-04.png`,
  `popup/popup-tutorial-bg01.png`,
  `popup/popup-tutorial-bg02.png`,
  `popup/popup-vdo-bg.png`,
];

images.forEach((image) => {
  new Image().src = `${process.env.CDN}/${image}`;
});
