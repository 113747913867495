export enum Error {
  ErrorGameLoginFailed = 'ERROR__GAME_LOGIN_FAILED',
  ErrorGopLoginFailed = 'ERROR__GOP_LOGIN_FAILED',
  ErrorBadRequest = 'ERROR__BAD_REQUEST',
  ErrorServerError = 'ERROR__SERVER_ERROR',
  ErrorServerBusy = 'ERROR__SERVER_BUSY',
  ErrorTemporaryClose = 'ERROR__TEMPORARY_CLOSE',
  ErrorNotEventPeriod = 'ERROR__NOT_EVENT_PERIOD',
  ErrorRefreshEvent = 'ERROR__REFRESH_EVENT',
  ErrorUnderMaintenance = 'ERROR__UNDER_MAINTENANCE',
  ErrorPaymentFailed = 'ERROR__PAYMENT_FAILED',
  ErrorInvalidParams = 'ERROR__INVALID_PARAMS',
  ErrorConfigError = 'ERROR__CONFIG_ERROR',
  ErrorForbidden = 'ERROR__FORBIDDEN',
  ErrorNoCharacters = 'ERROR__NO_CHARACTERS',
}

export enum WS_MESSAGE_TYPE {
  MARQUEE = 'marquee',
}

export enum Skips {
  Rules = 'rules',
  Video = 'video',
  DrawSingle = 'draw-single',
  DrawProp = 'draw-prop',
  Hint = 'hint',
}

export enum Rules {
  Rule = 'rule',
  Tutorial = 'tutorial',
}

export enum DrawType {
  Single = 0,
  SpecialOffer = 1,
  Prop = 2,
}

export enum Modal {
  CheckChoose = 'check-choose',
  CheckDraw = 'check-draw',
  CheckSpecialOffer = 'check-special-offer',
  Message = 'message',
}

export enum Popup {
  Choose = 'choose',
  DrawPool = 'draw-pool',
  Jackpot = 'jackpot',
  Receive = 'receive',
  Rules = 'rules',
  SpecialOffer = 'special-offer',
  ThaiAgreement = 'thai-agreement',
  Video = 'video',
}

export enum AnimationStatus {
  Initializing = 'initializing',
  Shuffling = 'shuffling',
  Pending = 'pending',
  Resulting = 'resulting',
}

export interface Reward {
  id: number | null;
  name: string | null;
  image: string | null;
  isWin: boolean;
  isOwned: boolean;
}

export interface PoolReward {
  id: number;
  name: string;
  image: string;
  isWin: boolean;
}

export interface ReceivedReward {
  name: string;
  image: string;
  isWin: boolean;
  isJackpot: boolean;
}

interface GuaranteeDraws {
  jackpot: number;
  win: number | null;
}

interface SpecialOffer {
  isConfirmed: boolean | null;
  originCost: number;
  discountCost: number;
  drawTimes: number;
}

export interface Cost {
  single: number;
  prop: number;
  specialOffer: SpecialOffer | null;
}

export interface GetProfileResponse {
  uid: string;
  username: string;
  cp: number;
  prop: number;
  completedEvent: boolean;
  enableWinReward: boolean;
  chose: boolean;
  drawPool: Array<Reward>;
  guaranteeDraws: GuaranteeDraws;
  cost: Cost;
  showConsent: boolean;
  youtubeVideoId: string;
  tutorialSteps: number;
  enableJackpotFrame: boolean;
}

export interface GetPoolResponse {
  rewards: Array<PoolReward>;
  neededChooseAmount: number;
}

export interface PostChooseParams {
  ids: Array<number>;
}

export interface PostChooseResponse {
  drawPool: Array<Reward>;
}

export interface PostDrawParams {
  cost: number;
  drawType: DrawType;
}

export interface PostDrawResponse {
  cp: number;
  prop: number;
  received: Array<ReceivedReward>;
  drawPool: Array<Reward>;
  guaranteeDraws: GuaranteeDraws;
  cost: Cost;
}

export interface PostSpecialOfferParams {
  isAccepted: boolean;
}

export type PostSpecialOfferResponse = Record<string, never>;

export interface PostConsentParams {
  isConsented: boolean;
}

export type PostConsentResponse = Record<string, never>;

// store
export type GameState = GetProfileResponse &
  GetPoolResponse & {
    received: Array<ReceivedReward>;
    choseRewardIds: Set<number>;
    isAccepted: boolean;
    isShowFloatText: boolean;
    floatText: {
      top: number | null;
      left: number | null;
      text: string;
    };
  };

export interface GeneralState {
  modalStack: Array<Modal>;
  popupStack: Array<Popup>;
  message: string;
  isLoading: boolean;
  isShowBtnConfirm: boolean;
  isWaitingAnimationShuffling: boolean;
}

export interface AnimationState {
  activeBoxId: number;
  animationStatus: AnimationStatus;
  jackpotWinners: Array<string>;
}
