import { defineStore } from 'pinia';
import { useGame } from './game';
import { useAnimation } from './animation';
import { Popup, Modal, GeneralState } from '@/services/types';
import constants from '@/constants';

const generalStore: GeneralState = {
  modalStack: [],
  popupStack: [],
  message: '',
  isLoading: false,
  isShowBtnConfirm: true,
  isWaitingAnimationShuffling: false,
};

export const useGeneral = defineStore('general', {
  state: () => generalStore,
  actions: {
    updateModalStack(modalStack: Array<Modal>) {
      this.modalStack = [...this.modalStack, ...modalStack];
    },
    closeCurrentModal() {
      this.modalStack.pop();
    },
    closeAllModal() {
      this.modalStack = [];
    },
    updatePopupStack(popupStack: Array<Popup>) {
      this.popupStack = [...this.popupStack, ...popupStack];
    },
    closeCurrentPopup() {
      this.popupStack.pop();
      this.checkAnimation();
    },
    closeAllPopup() {
      this.popupStack = [];
      this.checkAnimation();
    },
    closeAll() {
      this.closeAllPopup();
      this.closeAllModal();
    },
    handleExpectedError(message: string) {
      const { drawPool } = useGame();
      if (
        [
          'ERROR__GAME_LOGIN_FAILED',
          'ERROR__GOP_LOGIN_FAILED',
          'ERROR__NOT_EVENT_PERIOD',
          'ERROR__REFRESH_EVENT',
          'ERROR__UNDER_MAINTENANCE',
        ].includes(message) ||
        drawPool.length === 0
      ) {
        this.isShowBtnConfirm = false;
      }
      this.$patch(() => {
        this.modalStack.push(Modal.Message);
        this.isLoading = false;
        this.message = message;
      });
    },
    handleUnExpectedError() {
      this.$patch(() => {
        this.modalStack.push(Modal.Message);
        this.isLoading = false;
        this.message = constants.ERROR__SERVER_ERROR;
      });
    },
    updateIsLoading(isLoading: boolean) {
      this.isLoading = isLoading;
    },
    updateIsShowBtnConfirm(isShowBtnConfirm: boolean) {
      this.isShowBtnConfirm = isShowBtnConfirm;
    },
    updateIsWaitingAnimationShuffling(isWaitingAnimationShuffling: boolean) {
      this.isWaitingAnimationShuffling = isWaitingAnimationShuffling;
    },
    checkAnimation() {
      const { pendingChoose } = useGame();

      if (
        this.popupStack.length === 0 &&
        this.isWaitingAnimationShuffling &&
        !pendingChoose
      ) {
        this.isWaitingAnimationShuffling = false;

        const { handleAnimationShuffling } = useAnimation();
        handleAnimationShuffling();
      }
    },
  },
  getters: {
    currentModal: (state) => state.modalStack[state.modalStack.length - 1],
    currentPopup: (state) => state.popupStack[state.popupStack.length - 1],
  },
});
