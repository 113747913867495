import { defineStore, storeToRefs } from 'pinia';
import { useGeneral } from './general';
import { useAnimation } from './animation';
import services from '@/services';
import {
  Skips,
  DrawType,
  Popup,
  Modal,
  ReceivedReward,
  GameState,
} from '@/services/types';
import { initStorage, setStorage, getStorage } from '@/storageUtils';
import utils from '@/utils';
import { apiHandler } from './utils';

const gameState: GameState = {
  // profile
  uid: '',
  username: '',
  cp: 0,
  prop: 0,
  completedEvent: false,
  enableWinReward: false,
  chose: false,
  drawPool: [],
  guaranteeDraws: {
    jackpot: 0,
    win: 0,
  },
  cost: {
    single: 0,
    prop: 0,
    specialOffer: null,
  },
  showConsent: false,
  youtubeVideoId: '',
  tutorialSteps: 0,
  enableJackpotFrame: false,

  // pool
  rewards: [],
  neededChooseAmount: 0,

  // received api/draw
  received: [],

  // custom variable
  choseRewardIds: new Set<number>(), // for api/choose
  isAccepted: false, // for api/special_offer
  isShowFloatText: false,
  floatText: {
    top: null,
    left: null,
    text: '',
  },
};

export const useGame = defineStore('game', {
  state: () => gameState,
  actions: {
    async getProfile() {
      const response = await apiHandler(() => services.getProfile());

      if (response) {
        const { uid, ...restOfResponse } = response;
        this.$patch({
          uid,
          ...restOfResponse,
        });

        initStorage(uid); // init storage

        const { popupStack } = storeToRefs(useGeneral());
        const { updatePopupStack, updateIsWaitingAnimationShuffling } =
          useGeneral();

        // popup stack: ThaiAgreement -> Video -> Rules -> Pool -> SpecialOffer
        if (this.cost.specialOffer && !this.cost.specialOffer.isConfirmed) {
          updatePopupStack([Popup.SpecialOffer]);
        }
        if (this.enableWinReward && !this.chose) {
          await this.getPool();
        }
        if (!getStorage(Skips.Rules)) {
          updatePopupStack([Popup.Rules]);
          setStorage(Skips.Rules);
        }
        if (!getStorage(Skips.Video)) {
          updatePopupStack([Popup.Video]);
          setStorage(Skips.Video);
        }
        if (this.showConsent) {
          updatePopupStack([Popup.ThaiAgreement]);
        }

        if (popupStack.value.length === 0) {
          const { handleAnimationShuffling } = useAnimation();
          handleAnimationShuffling();
        } else {
          updateIsWaitingAnimationShuffling(true);
        }
      }
    },
    async getPool() {
      const response = await apiHandler(() => services.getPool());

      if (response) {
        this.$patch({
          ...response,
        });

        const { updatePopupStack } = useGeneral();
        updatePopupStack([Popup.Choose]);
      }
    },
    async postChoose() {
      const ids = [...this.choseRewardIds];
      const response = await apiHandler(() =>
        services.postChoose({
          ids,
        }),
      );

      if (response) {
        this.$patch({
          chose: true,
          ...response,
        });

        const { closeAllPopup, closeAllModal } = useGeneral();
        closeAllPopup();
        closeAllModal();
      }
    },
    async postDraw(skip: boolean) {
      const response = await apiHandler(() =>
        services.postDraw({
          cost: this.activeCost,
          drawType: this.drawType,
        }),
      );

      if (response) {
        const { cp, prop, received, drawPool, guaranteeDraws, cost } = response;
        this.$patch((state) => {
          state.cp = cp;
          state.prop = prop;
          state.received = received.reduce(
            (acc: Array<ReceivedReward>, curr) => {
              return curr.isJackpot ? [curr, ...acc] : [...acc, curr];
            },
            [],
          ); // jackpot shown in first index
          state.guaranteeDraws = guaranteeDraws;
        });

        // skip logic
        if (skip) {
          if (this.drawType === DrawType.Single) {
            setStorage(Skips.DrawSingle);
          } else if (this.drawType === DrawType.Prop) {
            setStorage(Skips.DrawProp);
          }
        }

        // popupStack
        const popupStack = [Popup.Receive];
        if (this.isReceivedJackpot) {
          popupStack.unshift(Popup.Jackpot);
        }
        if (!this.cost.specialOffer && cost.specialOffer) {
          popupStack.unshift(Popup.SpecialOffer);
        }

        // handle UI
        const {
          updatePopupStack,
          closeAllModal,
          updateIsWaitingAnimationShuffling,
        } = useGeneral();
        const { handleAnimationResulting } = useAnimation();

        closeAllModal();
        updateIsWaitingAnimationShuffling(true);

        // animation
        handleAnimationResulting();
        const delayTime = this.isReceivedJackpot ? 3000 : 1600;
        await utils.delay(delayTime);
        // popup
        updatePopupStack(popupStack);

        this.$patch({
          drawPool,
          cost,
        });
      }
    },
    async postSpecialOffer() {
      const response = await apiHandler(() =>
        services.postSpecialOffer({
          isAccepted: this.isAccepted,
        }),
      );

      if (response) {
        if (!this.isAccepted) {
          this.cost.specialOffer = null;
        }
        const { closeAll } = useGeneral();
        closeAll();
      }
    },
    async postConsent(isConsented: boolean) {
      const response = await apiHandler(() =>
        services.postConsent({
          isConsented,
        }),
      );

      if (response) {
        const { closeCurrentPopup } = useGeneral();
        closeCurrentPopup();
      }
    },
    checkDraw(activeBoxId: number) {
      const { updateModalStack, handleExpectedError } = useGeneral();
      const { updateActiveBoxId } = useAnimation();

      if (
        [DrawType.Single, DrawType.SpecialOffer].includes(this.drawType) &&
        this.cp < this.activeCost
      ) {
        handleExpectedError('ERROR__INSUFFICIENT_CP');
      } else {
        updateActiveBoxId(activeBoxId);

        if (
          this.drawType === DrawType.SpecialOffer ||
          (this.drawType === DrawType.Single &&
            !getStorage(Skips.DrawSingle)) ||
          (this.drawType === DrawType.Prop && !getStorage(Skips.DrawProp))
        ) {
          updateModalStack([Modal.CheckDraw]);
        } else {
          this.postDraw(true);
        }
      }
    },
    checkSpecialOffer(isAccepted: boolean) {
      this.isAccepted = isAccepted;

      const { updateModalStack } = useGeneral();
      updateModalStack([Modal.CheckSpecialOffer]);
    },
    updateChoseRewardIds(rewardId: number) {
      if (this.choseRewardIds.has(rewardId)) {
        this.choseRewardIds.delete(rewardId);
      } else {
        this.choseRewardIds.add(rewardId);
      }
    },
    resetChoseRewardIds() {
      this.choseRewardIds.clear();
    },
    updateFloatText(floatText: { top: number; left: number; text: string }) {
      this.isShowFloatText = true;
      this.floatText = floatText;
    },
    updateIsShowFloatText(isShowFloatText: boolean) {
      this.isShowFloatText = isShowFloatText;
    },
  },
  getters: {
    rewardList: (state) => state.drawPool.slice(0, 9),
    drawType: (state) => {
      let drawType = DrawType.Single;

      if (state.cost.prop > 0 && state.prop > 0) {
        drawType = DrawType.Prop;
      } else if (state.cost.specialOffer) {
        drawType = DrawType.SpecialOffer;
      }

      return drawType;
    },
    activeCost(): number {
      switch (this.drawType) {
        case DrawType.Single:
          return this.cost.single;
        case DrawType.SpecialOffer:
          return this.cost.specialOffer?.discountCost || 0;
        case DrawType.Prop:
          return this.cost.prop;
        default:
          return 0;
      }
    },
    boxCount: (state) => {
      const remainingRewardCount = state.drawPool.reduce((acc, curr) => {
        return acc + (curr.isOwned ? 0 : 1);
      }, 0);

      return remainingRewardCount > 3 ? 3 : remainingRewardCount;
    },
    pendingChoose: (state) => state.enableWinReward && !state.chose,
    isCompletedEvent: (state) =>
      state.completedEvent || state.drawPool.every((reward) => reward.isOwned),
    isReceivedJackpot: (state) =>
      state.received.some((reward) => reward.isJackpot),
    isGotJackpot: (state) =>
      state.received.some((reward) => reward.isJackpot) ||
      state.drawPool[0]?.isOwned,
  },
});
