import api from './api';
import {
  GetProfileResponse,
  GetPoolResponse,
  PostChooseParams,
  PostChooseResponse,
  PostDrawParams,
  PostDrawResponse,
  PostSpecialOfferParams,
  PostSpecialOfferResponse,
  PostConsentParams,
  PostConsentResponse,
} from './types';

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const mock = require('./mock').default;
  mock();
}

export default {
  getProfile: () => api<GetProfileResponse>('get', '/profile'),
  getPool: () => api<GetPoolResponse>('get', '/pool'),
  postChoose: ({ ids }: PostChooseParams) =>
    api<PostChooseResponse>('post', '/choose', { ids }),
  postDraw: ({ cost, drawType }: PostDrawParams) =>
    api<PostDrawResponse>('post', '/draw', { cost, drawType }),
  postSpecialOffer: ({ isAccepted }: PostSpecialOfferParams) =>
    api<PostSpecialOfferResponse>('post', '/special_offer', { isAccepted }),
  postConsent: ({ isConsented }: PostConsentParams) =>
    api<PostConsentResponse>('post', '/consent', { isConsented }),
};
