import { defineStore } from 'pinia';
import { useGame } from './game';
import { AnimationState, AnimationStatus } from '@/services/types';
import utils from '@/utils';

const animationState: AnimationState = {
  activeBoxId: 1,
  animationStatus: AnimationStatus.Initializing,
  jackpotWinners: [],
};

let marqueeTimer: ReturnType<typeof setInterval> | null = null;

export const useAnimation = defineStore('animation', {
  state: () => animationState,
  actions: {
    async handleAnimationShuffling() {
      const { boxCount, isGotJackpot } = useGame();
      this.activeBoxId = utils.randomNumber(1, boxCount);
      this.animationStatus = AnimationStatus.Shuffling;

      let delayTime = boxCount === 1 ? 1600 : 2900;
      if (isGotJackpot) {
        delayTime -= 1000;
      }

      await utils.delay(delayTime);
      this.animationStatus = AnimationStatus.Pending;
    },
    handleAnimationResulting() {
      this.animationStatus = AnimationStatus.Resulting;
    },
    updateActiveBoxId(activeBoxId: number) {
      this.activeBoxId = activeBoxId;
    },
    addJackpotWinners(name: string) {
      this.jackpotWinners.push(name);

      if (marqueeTimer === null) {
        marqueeTimer = setInterval(() => {
          this.removeJackpotWinners();
        }, 10000);
      }
    },
    removeJackpotWinners() {
      this.jackpotWinners.shift();

      if (marqueeTimer && this.jackpotWinners.length === 0) {
        clearInterval(marqueeTimer);
        marqueeTimer = null;
      }
    },
  },
});
