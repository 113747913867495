import { useGeneral } from './general';

export const apiHandler = async <T>(
  apiCall: () => Promise<T | { error: string }>,
): Promise<T | null> => {
  const { updateIsLoading, handleExpectedError, handleUnExpectedError } =
    useGeneral();
  updateIsLoading(true);

  try {
    const response = await apiCall();
    if ('error' in response) {
      handleExpectedError(response.error);
    } else {
      return response;
    }
  } catch (err) {
    handleUnExpectedError();
  } finally {
    updateIsLoading(false);
  }

  return null;
};
