<template>
  <div class="copyright">{{ $t('COPYRIGHT') }}</div>
</template>
<style lang="scss" scoped>
.copyright {
  font-family: 'Noto Serif TC';
  font-size: 0.17rem;
  font-weight: bold;
  color: #fcfcfc;
  position: absolute;
  left: 0.22rem;
  bottom: 0.02rem;
  &--popup {
    left: 0.07rem;
    bottom: -0.03rem;
  }
  &--consent {
    left: 0.07rem;
    bottom: -0.25rem;
  }
  &--jackpot,
  &--special-offer {
    right: 0;
    bottom: -0.03rem;
  }
}
</style>
